.get-started{
        background-color: crimson;
        padding: 10px 20px 10px 20px;
        margin-top: 30px;
        color: white;
        border: none;
        border-radius: 20px;
        text-transform: uppercase;
        font-weight: bold;
}

.home-quote h1 {
    font-size: 30px;
}

.serve{
    color: crimson;
}

h2::after{
    content: '';
    background: crimson;
    display: block;
    height: 3px;
    width: 170px;
    margin: 20px auto 5px;
}


.increase{
    border-right: 2px solid crimson;
}

@media only screen and (max-width: 992px) {

    .increase{
        border-right: none;
    }

}

.get-started:hover{
    cursor: pointer;
}



.ser{
    height: 80vh;
    position: relative;
    left: 60%;
    width: 40vw;
}


.li{
    font-size: 1.1em;
    font-weight: 1em;
 background: rgb(211,217,223);
background: linear-gradient(287deg, rgba(211,217,223,1) 0%, rgba(56,118,171,1) 85%);
}

.butto{
    justify-content:center;
    align-items: center;
}


/*Login forms*/

.body{
    height:auto;
    color: #3a3e42 !important;
}

.AppForm .AppFormLeft h1{
    font-size: 35px;
}
.AppForm .AppFormLeft input:focus{
    border-color: #ced4da;
}
.AppForm .AppFormLeft input::placeholder{
   font-size: 15px;
}
.AppForm .AppFormLeft i{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.AppForm .AppFormLeft a{
    color: #3a3e42 ;
}
.AppForm .AppFormLeft button{
    background: linear-gradient(45deg,#8D334C,#CF6964);
    border-radius: 30px;
}
.AppForm .AppFormLeft p span{
  color: #007bff;
}
.AppForm .AppFormRight{
    background-image: url('../partner.png');
    height: 450px;
    background-size: cover;
    background-position: center;
}
.AppForm .AppFormRight:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(95deg,crimson,crimson);
    opacity: 0.6;
}
.AppForm .AppFormRight h2{
    z-index: 1;
}
.AppForm .AppFormRight h2::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  bottom: 0;
  left:50%;
  transform: translateX(-50%);
}
.AppForm .AppFormRight p{
    z-index: 1;
}


/* Register Form Style*/

.regg{
    margin: 0;
    padding:0;
}

.contentss{
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
}

.shadow-box{
    background: white;
}

.regg p{
    padding: 0;
    margin: 0;
}

@media (min-width: 700px) {
    .shadow-box{
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    }
}

/*@media (max-width: 600px){
    .home-quote h3{
        font-size: 19px;
    }
}*/

.signup-wrappers .company-details {
    background: url('../nirman-sathi.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  position:relative;
}

.signup-wrappers .company-details:before {
    content: "";
  position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%);
}

.signup-wrappers .company-details .wrapper-1{
    position: relative;
    padding-bottom: 10px;
    padding-top: 22px;

}

.signup-wrappers .company-details .logo {
    padding: 0 20px;
    width: 20%;
    margin: 0 auto;
}

.signup-wrappers .company-details .logo .icon-food {
    background-size:contain ;
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    margin: 0 auto;
}


.signup-wrappers .company-details .title {
    padding: 20px;
    width: 74%;
    margin: 0 auto;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    text-shadow: 1px 1px #4b4a4a;
    text-align: center;

}
.signup-wrappers .company-details .slogan {
   display: none;
}

.signup-wrappers .signup-form .wrapper-2{
    padding: 14px 20px;
    margin: 30px 80px;
}
.signup-wrappers .signup-form .form-title{
    font-size: 1.5em;
    color:  crimson;
    padding: 5px;
    text-align: center;

}

.signup-wrappers .signup-form .form .content-item{
    text-transform: uppercase;
    color: #A19F9C;
    font-size: 1em;
    letter-spacing: 1px;
    margin-top: 35px;
    text-align: center;
    outline: none;
    border-width:0px;
    border:none;
    border-color: white;
}

.signup-wrappers .signup-form .form input[type=text],.signup-wrapper .signup-form .form input[type=password]{
    border: 0;
    border-bottom: 2px solid #e4e4e4;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    width: 170%;
}


.signup-wrappers .signup-form .signup {
    background-color: crimson;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    text-transform: capitalize;
    margin-top: 40px;
    border-radius: 5px;

}

.signup-wrappers .signup-form {
    color: #D60CA7;
    padding: 20px;
    font-weight: 600;
    text-decoration: none;
    font-size: 0.9em;
    width: 95%;
    margin: 0 auto;
    display: inline-block;
    text-align: center;

}

.logins{
    text-decoration: none;
    color: crimson;
    font-weight: 650;
    position: absolute;
    font-size: 1em;
    text-align: center;
    left: 40%;
    top: 80%;

}

.regg input::placeholder {
    color: #575757;
}

.regg input:focus{
  border: none;
  outline: none;
}

.reg input[type=password]{
  outline: none;
  border-bottom: none;
}

.reg input[type=password]:focus{
  border: none;
  outline: none;
}

@media (min-width: 200px) {

    .signup-wrappers .signup-form .wrapper-2{
        margin: 30px 23px;
    }

    .signup-wrappers .company-details .title {
        width: 90%;
        font-size: 1.5em;
    }

    .content-item{
        width: 100%;
    }

}

@media (min-width: 300px) {
    .signup-wrappers .signup-form{
        display: inline-block;
    }
    .signup-wrappers .signup-form .wrapper-2{
        margin: 30px 60px;
    }

    .signup-wrappers .company-details .title {
        width: 80%;
       font-size: 2em;
    }



}

@media (min-width: 500px) {
    .signup-wrappers .signup-form{
        display: inline;
    }
}

@media (min-width: 700px) {
    .signup-wrappers .company-details,
    .signup-wrappers .signup-form {
        width: 50%;
    }
    .signup-wrappers {
       display: flex;
        max-width: 700px;
        margin: 0 auto;
        margin-top: 5%;
    }
    .signup-wrappers .company-details .wrapper-1 {
        padding-bottom: 150px;
        padding-top: 160px;
    }
    .signup-wrappers .company-details .slogan {
        padding: 0 20px;
        width: 70%;
        margin: 0 auto;
        color: #fff;
        font-size: 1.2em;
        text-align: center;
        text-shadow: 1px 1px #4b4a4a;
        display: block;
    }
    .signup-wrappers .company-details .title {
        width: 76%;
    }
    .signup-wrappers .signup-form .wrapper-2{
        padding: 50px 40px;
        margin: 0;
    }

    .signup-wrappers .signup-form{
        display: inline;
    }
    .signup-wrappers .signup-form .form .content-item{
        text-align: initial;
    }
    .signup-wrappers .signup-form .form input[type=text],.signup-wrapper .signup-form .form input[type=password]{
        text-align: initial;



    }
   .signup-wrappers .signup-form .form-title{
        text-align: initial;
        padding :0;
    }

}

.Aboutus{
    overflow-x: hidden;
}

.about{
    width: 100%;
    height: 60vh;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.1)),url("../nirmanban.png");
    background-position: center;
    background-size: cover;
    padding-left: 8%;
    padding-right: 8%;
    box-sizing: border-box;
}


.row{
    display: flex;
    height: auto;
    align-items: center;
}

.col{
    flex-basis: 50%;
}
.cardss{
    width: 210px;
    height: 200px;
    display: inline-block;
    border-radius: 10px;
    padding: 15px 25px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 15px 0px 0px 10px;
    background-position: center;
    background-size: cover;
    transition: transform 0.5s

}


.card1{
    background-image: url('../card1.jpg');
        background-position: center;
        background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    left: 40%;
}

.card2{
    background-image: url('../card2.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    left: 42%;
}

.card3{
    background-image: url('../card3.jpg');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    left: 40%;
    top: 0%;
}

.card4{
    background-image: url('../card4.jpg');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    left: 42%;
    top: 0%;
}

.cardss:hover{
    transform: translateY(-15px);
}

@media only screen and (max-width: 1400px) {
    .about{
        display: none;
    }
}

.section{
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background: #f5f8fc;
}

.section::before{
    content: '';
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: white;
}

.aboutcontainer{
    position: relative;
    min-width: 100vh;
    min-height: 380px;
    display: flex;
    z-index: 1000;
}

.aboutpageinfo{
    position: absolute;
    top: 40px;
    width: 350px;
    height: calc(100% - 120px);
    background: #0f3959;
    z-index: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
}

.aboutpageinfo h2{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.aboutpagedetail{
    position: absolute;
    padding: 40px 50px 0px 50px;
    background: #f5f8fc;
    margin-left: 350px;
    width: calc(80% - 150px);
    height: 100%;
}

.detailbox{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
}

.aboutpagedetail p {
    font-size: 18px;
    color: black;
    position: relative;
    z-index: 10000;
    font-weight: 600;
}


.detailbox img{
    position: absolute;
    width: 80%;
    height: 80%;
    opacity: 0.3;
}

.ourCollection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    width: auto;
    margin-top: 5%;
}

.ourcards{
    position: relative;
    width: 350px;
    height: 350px;
    margin: 30px;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.ourbox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 1s ease;
}

.ourcards:hover .ourbox{
    transform: rotateY(180deg);
}

.imgBox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.imgBox img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: 100% 100%;
}

.contentBx{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}

.contentBx div{
    transform-style: preserve-3d;
    padding: 20px;
    background: linear-gradient(75deg, crimson, crimson);
    transform: translateZ(100px);
}

.contentBx div h3{
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
}

.contentBx div p{
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1200px) {
    .section::before{
        position: relative;
    }



    .detailbox{
        max-width: 90vh;
    }

    .aboutpara::before {
        content: 'About Us';
        font-size: 24px;
        color: crimson;
        text-decoration: underline;
        margin: 0px 20px;
    }

    .story::before{
        content: 'Our Story';
        font-size: 24px;
        color: crimson;
        text-decoration: underline;
        margin: 0px 20px;
    }

    .detailbox img{
        display: none;
    }

.aboutcontainer{
        min-width: 90vw;
        max-height: 100vh;
        position: relative;
    }

    .aboutpageinfo{
        display: none;
    }

.aboutpagedetail{
    margin-left: 0;
    position: static;
    padding: 0px;
    width: 90vh;
    height: auto;

}

..aboutpagedetail p{
    font-size: 10px;
}

}

.tnc p{
    font-size: 1.2rem;
}

.tnc li{
    font-size: 1.1rem;
}
