*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

a{
    text-decoration: none;
}

html{
    scroll-behavior: smooth;
}

body{
    overflow-x: hidden;
}

.active-right{
    left: 0%;
    opacity: 1;
}

.active-left{
    opacity: 1;
}

.serviceCard{
    z-index: 40;
    display: flex;
    align-items: center;
    justify-content: center;
}

.serviceList{
    opacity: 0;
    z-index: 50;
    position: absolute;
    height: 100%;
    width: 80%;
    font-weight: 1.1em;
 background: rgb(211,217,223);
background: linear-gradient(287deg, rgba(211,217,223,1) 0%, rgba(56,118,171,1) 85%);
    color: white;
    transition-duration: 0.5s;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.serviceCard:hover .serviceList{
    opacity: 1;
}

.serviceList a{
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
}

@media only screen and (max-width: 900px) {
    .serviceList{
        opacity: 0.9;
    }

}


