.selected:after{
    width: 25px;
    height: 3px;
    position: relative;
    display: block;
    content: '';
    bottom: -5px;
    margin: auto;
    background-color: #ccc;
    font-weight: bolder;
}

.card-body{
    height: 50px !important;
}


.checked{
      color: orange;
}

.cli{
    margin: 40px auto;
}

.profile{
    padding: 70px 10px 10px;
    background-color: #f5f8fc;
}

.profile img{
    top: -52px;
    position: absolute;
    left: calc(50% -60px);
    border: 10px solid white;
}

.user{
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.profile h5{
    font-size: 20px;
    margin-top: 15px;
    color: black;
}

.profile h6{
    font-size: 12px;
    color: black;
}

blockquote{
    font-size: 16px;
    line-height: 30px;
    font-weight: italic;
}


.profile:hover{
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
}

.ove{
    overflow: hidden;
}

.ph{
    overflow: hidden;
    width: 80%;
    height: 200px;
    object-fit: cover;
}
.sercice-imgContainer{
    width: 100%;
    display:flex;
    justify-content: center;
}

/*service detail page css starts*/
.wrapper{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-wrap: wrap;
}

.left-col, .right-col{
    flex-basis: 50%;
    height: 100vh;
    position: relative;
}

.content{
    max-width: 500px;
    position: absolute;
    top: 30%;
    left: 100px;
    transform: translateY(-50%);
}

.content h3{
    color: crimson;
    font-size: 50px;
    line-height: 50px;
    margin: 25px 0;
}

.content h6{
    color: #777;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 50px;
}


.sliderr{
    width: 100%;
    height: 60%;
    display: flex;
}

.sliderr img{
    width: 100%;
}

.slider-content{
    width: 100%;
    height: 32%;
    padding-top: 30px;
    padding-left: 30px;
    box-sizing: border-box;
}

.slider-content h4{
    font-size: 26px;
    font-weight: 7rem;
    color: crimson;
    margin-top: -10px;
    margin-bottom: 16px;
}

.slider-content p{
    color: black;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 12px;
}


.controller{
    width: 120px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
}

.icons{
    width: 45px;
    cursor: pointer;
}

@media only screen and (max-width: 800px){
    .left-col, .right-col{
        flex-basis: 100%;
    }

    .content{
        left: 0;
        padding: 20px;
        text-align: center;
    }

    .content h3{
        margin-top: 200px;
        font-size: 34px;
        line-height: 38px;
    }

    .sliderr{
        height: auto;
        margin-top: -90px;
    }


    .slider-content{
        height: auto;
        box-sizing: border-box;
    }

    .left-col{
        height: 100vh;
    }

    .wrapper{
        overflow-y: scroll;
    }

}
